<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" lg="6" md="6">
        <!-- 使用卡片 -->
        <!-- 
          v-card-title:卡片的标题
          v-card-text:卡片的内容
          v-card-actions:卡片的操作
       -->
        <v-card>
          <v-card-title class="elevation-3 font-weight-bold"
            >添加用户</v-card-title
          >
          <v-card-text>
            <!-- 渲染表单 -->
            <v-form>
              <!-- 输入框 -->
              <!-- lable属性:输入框的提示信息 -->
              <v-text-field label="姓名" v-model="user.name"></v-text-field>
              <v-text-field label="年龄" v-model="user.age"></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="add">添加</v-btn>
            <v-btn text color="primary" to="/users/Userlist">取消</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
//导入axios
import axios from "axios";

export default {
  data() {
    return {
      user: {
        name: "",
        age: ""
      }
    }
  },

  methods: {
    //添加add方法
    add() {
      //通过this.user可以获取到页面输入的数据
      //通过axios发送请求,请求添加接口(post/users)
      axios.post("http://localhost:3000/users", this.user).then((resp) => {
        //结构数据
        const { status, data } = resp
        if (status == 201) {
          //添加成功
          alert("添加成功!")
          //页面跳转
          this.$router.push('/users/Userlist')
        }
      })
    }
  }
};
</script>

<style >
</style>