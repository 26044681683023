<template>
  <v-row justify="center">
    <v-col sm="6" xl="10">
      <v-data-table
        :headers="headers"
        :items="desserts"
        :items-per-page="5"
        sort-by="calories"
        class="elevation-1"
      >
        <template v-slot:top>
          <!-- v-toolbar 添加工具条 -->
          <v-toolbar color="orange" dark>
            <!-- v-toolbar-title工具条的标题 -->
            <v-toolbar-title>My CRUD</v-toolbar-title>
            <!-- v-divider分割线 -->
            <v-divider class="mx-4" inset vertical></v-divider>
            <!-- v-spacer空白占位符 -->
            <v-spacer></v-spacer>
            <v-btn rounded small dark to="/About">About us </v-btn>
            <!-- v-dialog用来弹窗 -->
            <!-- 此弹窗用于进行编辑或增加记录使用 -->
            <v-dialog v-model="dialog" max-width="500px">
              
              <template v-slot:activator="{ on, attrs }">
                <v-btn small rounded v-bind="attrs" v-on="on">New Item</v-btn>
              </template>

              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="id" label="id"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="name" label="姓名"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="age" label="年龄"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- 该弹窗用来确认是否删除一条记录 -->
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
        
        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize"> Reset </v-btn>
        </template>

      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
//导入axios包
import axios from "axios";
//表单js
export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: "ID", value: "id" },
      { text: "姓名", value: "name" },
      { text: "年龄", value: "age" },
      { text: "操作", value: "actions", sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      id: 0,
      name: "",
      age: 0,
    },
    defaultItem: {
      id: 0,
      name: "",
      age: 0,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "增加" : "修改";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      //axios方法
      //  ge:发送get请求
      //  参数url
      //  then:成功后的回调
      axios.get("http://localhost:3000/users").then((resp) => {
        const { status, data } = resp; //对数据进行解构
        if (status == 200) {
          this.desserts = data;
        }
      });

      /*     this.desserts = [
        {
          id: 1,
          name: "Frozen Yogurt",
          age: 159,
        },
        {
          id: 2,
          name: "Ice cream sandwich",
          age: 237,
        },
        {
          id: 3,
          name: "Eclair",
          age: 262,
        },
        {
          id: 4,
          name: "Cupcake",
          age: 305,
        },
        {
          id: 5,
          name: "Gingerbread",
          age: 356,
        },
        {
          id: 6,
          name: "Jelly bean",
          age: 375,
        }, 
      ] */
    },

    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      console.log(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      //nextTick()，是将回调函数延迟在下一次dom更新数据后调用，简单的理解是：当数据更新了，在dom中渲染后，自动执行该函数
      this.$nextTick(() => {
        //Object.assign用于将数据集合并到目标集中
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    },
  },
};
</script>


<style>
</style>