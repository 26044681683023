import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue')

  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About.vue')
  },
  // Vuetyfy栅格系统
  {
    path: '/vuetify/grid',
    name: 'grid',
    component: () => import('@/views/vuetify/grid.vue')
  },
  // 用户列表
  {
    path: '/users/Userlst',
    name: 'users',
    component: () => import('@/views/users/Userlst.vue')
  },
  // 仿教程用户列表
  {
    path: '/users/Userlist',
    name: 'users01',
    component: () => import('@/views/users/Userlist.vue')
  },
  // 输入页面
  {
    path: '/users/UserAdd',
    name: 'Useradd',
    component: () => import('@/views/users/UserAdd.vue')
  },
  // 修改页面
  {
    path: '/users/UserEdit/:id', //id只是一个占位符
    name: 'Usereidt',
    component: () => import('@/views/users/UserEdit.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
