<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" lg="6" md="6">
        <!-- 使用卡片 -->
        <!-- 
          v-card-title:卡片的标题
          v-card-text:卡片的内容
          v-card-actions:卡片的操作
       -->
        <v-card>
          <v-card-title class="elevation-3 font-weight-bold">修改</v-card-title>
          <v-card-text>
            <!-- 渲染表单 -->
            <v-form>
              <!-- 输入框 -->
              <!-- lable属性:输入框的提示信息 -->
              <v-text-field label="姓名" v-model="user.name"></v-text-field>
              <v-text-field label="手机" v-model="user.hp"></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="edit">修改</v-btn>
            <v-btn text color="primary" to="/users/Userlist">取消</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      user: {
        name: "",
        age: "",
      },
      id: "", //用于保存需要修改的数据id值
    };
  },

  methods: {
    edit() {
      axios
        .put("http://ohm5m04o.dongtaiyuming.net/fd/" + this.id, this.user)
        .then((resp) => {
          const { status, data } = resp;
          if (status == 200) {
            alert("修改成功!");
            this.$router.push("/users/Userlist");
          }
        });
    },
  },
  created() {
    //this.router表示路由对象,操作是通过对象
    //this.$route表示路由规则
    this.id = this.$route.params.id; //提取路由规则中的id值
    //向接口发送请求,获取单条记录(get/users/:id)
    axios.get("http://ohm5m04o.dongtaiyuming.net/fd/" + this.id).then((resp) => {
      //结构resp
      const { status, data } = resp;
      //判断
      if (status == 200) {
        this.user = data;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
</style>