<template>
  <v-card>
    <router-view />
  </v-card>
</template>

<script>
export default {
  data: () => ({}),
};
</script>

<style lang="scss" scoped>
</style>