<template>
  <v-row justify="center">
    <v-col cols="12" md="6">
      <!-- 工具栏 -->
      <v-toolbar color="orange" dark height="30">
        <v-toolbar-title class="font-weight-black">用户列表</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn text to="/users/UserAdd">添加用户</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <!-- 渲染一个表格 -->
      <v-data-table :headers="headers" :items="users" :items-per-page="5">
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
//导入axios包
import axios from "axios";

export default {
  data: () => ({
    headers: [
      { text: "id", value: "id" },
      { text: "姓名", value: "name" },
      { text: "手机", value: "hp" },
      { text: "操作", value: "actions", sortable: false },
    ],
    users: [],
  }),

  created() {
    this.showlist();
  },

  methods: {
    showlist() {
      //axios方法
      //  ge:发送get请求
      //  参数url
      //  then:成功后的回调
      axios.get("http://ohm5m04o.dongtaiyuming.net/lst").then((resp) => {
        const { status, data } = resp; //对数据进行解构
        if (status == 200) {
          this.users = data;
        }
      });

      // this.users = [
      //   {
      //     id: 1,
      //     name: "Frozen Yogurt",
      //     age: 159,
      //   },
      //   {
      //     id: 2,
      //     name: "Ice cream sandwich",
      //     age: 237,
      //   },
      // ];
    },

    editItem(rowdata) {
      // 获取选中记录的id
      const { id } = rowdata;
      // 将id传值并跳转到修改页面
      this.$router.push("/users/UserEdit/" + id);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>